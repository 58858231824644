import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import { Contextfn } from "./context/Context";
import { BrowserRouter, Routes, Route } from "react-router-dom";
const Home = React.lazy(() => import("./pages/Home"));
const AboutUs = React.lazy(() => import("./pages/AboutUs"));
const InternationalPatients = React.lazy(() =>
  import("./pages/InternationalPatients")
);
const CorporateInsuranceServices = React.lazy(() =>
  import("./pages/CorporateInsuranceServices")
);
const Charity = React.lazy(() => import("./pages/Charity"));
const Education = React.lazy(() => import("./pages/Education"));
const Research = React.lazy(() => import("./pages/Research"));
const Footcare = React.lazy(() => import("./pages/PatientCare/Footcare"));
const EyeCare = React.lazy(() => import("./pages/PatientCare/EyeCare"));
const CardiacCare = React.lazy(() => import("./pages/PatientCare/CardiacCare"));
const KidneyCare = React.lazy(() => import("./pages/PatientCare/KidneyCare"));
const OrthopedicCare = React.lazy(() =>
  import("./pages/PatientCare/OrthopedicCare")
);
const Surgeries = React.lazy(() => import("./pages/PatientCare/Surgeries"));
const DentalCare = React.lazy(() => import("./pages/PatientCare/DentalCare"));
const HomeCare = React.lazy(() => import("./pages/PatientCare/HomeCare"));
const Doctors = React.lazy(() => import("./pages/Doctors"));
const BookAppointment = React.lazy(() => import("./pages/BookAppointment"));
const PatientCare = React.lazy(() => import("./pages/PatientCare"));
const Tribute = React.lazy(() => import("./pages/Tribute"));
const OurGrowthStory = React.lazy(() => import("./pages/OurGrowthStory"));
const Testimonials = React.lazy(() => import("./pages/Testimonials"));
// import { motion } from "framer-motion";

function App() {
  return (
    <Contextfn>
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <React.Suspense fallback={<Loading />}>
                <Home />
              </React.Suspense>
            }
          />
          <Route
            path="/about-us"
            element={
              <React.Suspense fallback={<Loading />}>
                <AboutUs />
              </React.Suspense>
            }
          />
          <Route
            path="/international-patients"
            element={
              <React.Suspense fallback={<Loading />}>
                <InternationalPatients />
              </React.Suspense>
            }
          />
          <Route
            path="/corporate-insurance-services"
            element={
              <React.Suspense fallback={<Loading />}>
                <CorporateInsuranceServices />
              </React.Suspense>
            }
          />
          <Route
            path="/charity"
            element={
              <React.Suspense fallback={<Loading />}>
                <Charity />
              </React.Suspense>
            }
          />
          <Route
            path="/education"
            element={
              <React.Suspense fallback={<Loading />}>
                <Education />
              </React.Suspense>
            }
          />
          <Route
            path="/research"
            element={
              <React.Suspense fallback={<Loading />}>
                <Research />
              </React.Suspense>
            }
          />
          <Route
            path="/doctors"
            element={
              <React.Suspense fallback={<Loading />}>
                <Doctors />
              </React.Suspense>
            }
          />
          <Route
            path="/tribute"
            element={
              <React.Suspense fallback={<Loading />}>
                <Tribute />
              </React.Suspense>
            }
          />
          <Route
            path="/our-growth-story"
            element={
              <React.Suspense fallback={<Loading />}>
                <OurGrowthStory />
              </React.Suspense>
            }
          />
          <Route
            path="/testimonials"
            element={
              <React.Suspense fallback={<Loading />}>
                <Testimonials />
              </React.Suspense>
            }
          />
          <Route
            path="/patient-care/:slug_name"
            element={
              <React.Suspense fallback={<Loading />}>
                <PatientCare />
              </React.Suspense>
            }
          />
          {/* <Route
            path="/patient-care/foot-care"
            element={
              <React.Suspense fallback={<Loading />}>
                <Footcare />
              </React.Suspense>
            }
          />
          <Route
            path="/patient-care/eye-care"
            element={
              <React.Suspense fallback={<Loading />}>
                <EyeCare />
              </React.Suspense>
            }
          />
          <Route
            path="/patient-care/cardiac-care"
            element={
              <React.Suspense fallback={<Loading />}>
                <CardiacCare />
              </React.Suspense>
            }
          />
          <Route
            path="/patient-care/home-care"
            element={
              <React.Suspense fallback={<Loading />}>
                <HomeCare />
              </React.Suspense>
            }
          />
          <Route
            path="/patient-care/kidney-care"
            element={
              <React.Suspense fallback={<Loading />}>
                <KidneyCare />
              </React.Suspense>
            }
          />
          <Route
            path="/patient-care/orthopedic-care"
            element={
              <React.Suspense fallback={<Loading />}>
                <OrthopedicCare />
              </React.Suspense>
            }
          />
          <Route
            path="/patient-care/surgeries"
            element={
              <React.Suspense fallback={<Loading />}>
                <Surgeries />
              </React.Suspense>
            }
          />
          <Route
            path="/patient-care/dental-care"
            element={
              <React.Suspense fallback={<Loading />}>
                <DentalCare />
              </React.Suspense>
            }
          /> */}
          <Route
            path="/book-appointment"
            element={
              <React.Suspense fallback={<Loading />}>
                <BookAppointment />
              </React.Suspense>
            }
          />
          <Route
            path="*"
            element={
              <div
                style={{
                  height: "100vh",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <h1 className="text-1 tc-2 fw-bold" style={{ textAlign: "center" }}>404</h1>
              </div>
            }
          />
        </Routes>
      </BrowserRouter>
    </Contextfn>
  );
}

export default App;

export function Loading() {
  return (
    <div
      style={{
        height: "100vh",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <h1 style={{ textAlign: "center" }}>Loading...</h1>
    </div>
  );
}
