import React, { createContext } from "react";
 const ThemeContext = createContext();

function Contextfn({ children }) {
    // const ThemeContext = createContext();
  const [footerContent, setFooterContent] = React.useState(null);

  return (
    <ThemeContext.Provider value={{footerContent,setFooterContent}}>
      {children}
    </ThemeContext.Provider>
  );
}

// export default Contextfn;

export { Contextfn, ThemeContext };